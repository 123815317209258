<template>
  <div class="mt-8 d-flex flex-column align-center indicate-vikings">
    <v-text-field
      v-model="name"
      flat
      solo
      class="rounded-lg search-field"
      background-color="textInputPost"
      clearable
      clear-icon="mdi-close"
      color="black"
      :placeholder="$t('components.vikings_to_indicate.find_by_name')"
      @click="trackSearchEvent"
    >
      <template v-slot:append>
        <v-img
          src="@/assets/img/search.svg"
          max-width="48"
          max-height="48"
        />
      </template>
    </v-text-field>

    <div class="mt-10 d-flex show-vikings">
      <viking-to-indicate
        v-for="user in usersFiltered"
        :key="user.id"
        ref="vikingsRef"
        class="ma-3"
        :viking="user"
        :user-id="currentUser.id"
        @nominateUser="nominateUser"
        @removeNominatedUser="removeNominatedUser"
      />
    </div>
    <v-snackbar
      v-model="snackbar"
      color="notificationError"
      class="snackbar"
      width="572"
      height="48"
    >
      <span class="ml-4 snackbar-text">
        {{ $t('components.vikings_to_indicate.no_indication') }}
      </span>
      <template v-slot:action="{ attrs }">
        <v-icon
          v-bind="attrs"
          class="mr-4 no-background"
          @click="snackbar = false"
        >
          mdi-close
        </v-icon>
      </template>
    </v-snackbar>
    <v-row class="justify-center footer-btns align-center">
      <div>
        <indicate-button
          class="mr-6"
          type-btn="back"
        />
        <indicate-button
          type-btn="indicate"
          @doNominations="doNominations"
        />
      </div>
    </v-row>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import VikingToIndicate from '@/components/globals/VikingToIndicate.vue';
import IndicateButton from '@/components/globals/IndicateButton.vue';
import { trackEvent } from '../../plugins/mixpanel';

export default {
  name: 'VikingsList',
  components: {
    VikingToIndicate,
    IndicateButton,
  },
  props: {
    users: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      name: '',
      nominations: [],
      snackbar: false,
    };
  },
  computed: {
    ...mapGetters(['currentUser']),
    usersFiltered() {
      if (!this.name || this.name === '') {
        return this.users;
      }
      return this.userFilter();
    },
  },
  methods: {
    ...mapActions(['saveSelectedNominations']),
    nominateUser(user) {
      this.nominations.push({ nominated: user, justification: '' });
      this.changeSelectedViking(user);
      this.saveSelectedNominations(this.nominations);
    },
    removeNominatedUser(user) {
      this.nominations = this.nominations.filter(
        (nomination) => nomination.nominated.id !== user.id,
      );
      this.changeSelectedViking(user);
      this.saveSelectedNominations(this.nominations);
    },
    changeSelectedViking(user) {
      const { vikingsRef } = this.$refs;

      vikingsRef.forEach((vikingRef) => {
        if (vikingRef.viking.id === user.id) {
          vikingRef.changeSelected();
        }
      });
    },
    doNominations() {
      if (this.nominations.length > 0) {
        this.$router.push('/valhalla/nominees');
      } else {
        this.snackbar = true;
      }
    },
    userFilter() {
      return this.users.filter((user) => user.name.toLowerCase().includes(this.name.toLowerCase()));
    },
    trackSearchEvent() {
      trackEvent('clicou_busca_viking');
    },
  },
};
</script>

<style lang="scss" scoped>
.indicate-vikings {
  font: 16px/24px NunitoRegular;
  padding-bottom: 60px;

  .search-field {
    height: 44px;
    width: 462px;
    letter-spacing: 0.5px;
    color: $form-filter-to-indicate;
  }

  .footer-btns {
    width: 100%;
    position: fixed;
    bottom: 30px;
    z-index: 20;
  }
  .show-vikings {
    flex-wrap: wrap;
    align-items: center;
  }
  .snackbar {
    border-radius: 4px;
    margin-bottom: 80px;
    .snackbar-text {
      font: 12px/16px NunitoRegular;
      color: #f7f7f7;
      letter-spacing: 0.4px;
      text-align: left;
    }
  }
}
</style>
