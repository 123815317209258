<template>
  <v-container class="text-center">
    <div>
      <h1 class="indicate-title">
        {{ $t('views.indicate.indicate_to_valhalla') }}
      </h1>
    </div>
    <div>
      <h4 class="mt-3 indicate-subtitle">
        {{ $t('views.indicate.indicate_subtitle') }}
      </h4>
    </div>
    <vikings-list :users="sortUsers" />
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex';
import VikingsList from '@/components/globals/VikingsList.vue';

export default {
  name: 'Indicate',
  components: {
    VikingsList,
  },
  computed: {
    ...mapGetters(['getUsers']),
    sortUsers() {
      const users = this.getUsers;

      return users.sort((a, b) => (a.name > b.name ? 1 : -1));
    },
  },
};
</script>

<style lang="scss" scoped>
.indicate-title {
  color: $primary;
  font: 600 34px/36px MontserratRegular;
  max-width: 1240px;
  margin: 0 auto;
}

.indicate-subtitle {
  font: 16px/24px MontserratRegular;
  letter-spacing: 0.14px;
  max-width: 604px;
  margin: 0 auto;
}
</style>
