<template>
  <v-card
    :class="`indicate-style justify-center ${isSelected}`"
    :color="getColor"
    rounded="lg"
    @click="selectViking()"
  >
    <v-avatar class="mt-4">
      <img
        alt="Avatar"
        :src="viking.photo_url"
      >
    </v-avatar>
    <v-card-title :class="`text-center user-name ${disabled} ${isSelected}`">
      {{ viking.pretty_name }}
    </v-card-title>

    <v-overlay
      color="primary"
      absolute
      :value="isNominated"
    >
      <span class="overlay-txt">Indicado</span>
    </v-overlay>
  </v-card>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'VikingToIndicate',
  props: {
    viking: {
      type: Object,
      default: () => {},
    },
    userId: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      selected: false,
    };
  },
  computed: {
    isNominated() {
      return this.viking.own_nominations?.some((nomination) => nomination.user_id === this.userId);
    },
    isSelected() {
      if (this.selected) {
        return 'selected';
      }
      return '';
    },
    getColor() {
      if (this.selected) {
        return 'primary';
      }
      return '';
    },
    disabled() {
      if (this.isNominated) {
        return 'disabled';
      }
      return '';
    },
    ...mapGetters(['getSelectedNominations']),
  },
  methods: {
    selectViking() {
      if (!this.isNominated) {
        if (!this.selected) {
          this.$emit('nominateUser', this.viking);
        } else {
          this.$emit('removeNominatedUser', this.viking);
        }
      }
    },
    changeSelected() {
      this.selected = !this.selected;
    },
  },
};
</script>

<style lang="scss" scoped>
.indicate-style {
  width: 136px;
  height: 148px;
  box-shadow: 0px 4px 8px $shadow-viking !important;

  .user-name {
    font: normal normal bold 16px/24px MontserratBold;
    letter-spacing: 0.14px;
    justify-content: center;
    word-break: break-word;

    &.disabled {
      color: $disabled;
    }

    &.selected {
      color: $text-light;
    }
  }

  .overlay-txt {
    font: normal normal bold 14px/16px NunitoRegular;
    color: $primary-darker;
  }
}
</style>
