<template>
  <v-btn
    elevation="2"
    dark
    :class="`btn ${typeBtn}`"
    @click="doAction()"
  >
    {{ getMessage }}
  </v-btn>
</template>

<script>
import { trackEvent } from '../../plugins/mixpanel';

export default {
  name: 'IndicateButton',
  props: {
    typeBtn: {
      type: String,
      default: '',
    },
  },
  computed: {
    getMessage() {
      if (this.typeBtn === 'back') {
        return this.$t('components.vikings_to_indicate.back_votation');
      }

      if (this.typeBtn === 'send') {
        return this.$t('components.vikings_to_indicate.send_votes');
      }

      if (this.typeBtn === 'change') {
        return this.$t('components.vikings_to_indicate.change_indications');
      }

      return this.$t('components.vikings_to_indicate.indicate_confirm');
    },
  },
  methods: {
    doAction() {
      switch (this.typeBtn) {
        case 'back':
          trackEvent('clicou_ver_indicacoes');
          this.$router.push('/valhalla');
          break;
        case 'indicate':
          trackEvent('clicou_indicar_viking');
          this.$emit('doNominations');
          break;
        case 'change':
          trackEvent('clicou_alterar_indicacao');
          this.$router.push('/valhalla/indicate');
          break;
        default:
          trackEvent('clicou_finalizar_indicacao');
          this.$emit('doNominations');
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.btn {
  display: inline-block;
  text-transform: initial;
  font-family: 'NunitoBold';
  width: 274px;
  height: 52px;
  letter-spacing: 1.25px;
  box-shadow: 0px 8px 20px #09662E4D;
  border-radius: 4px;

  &.back, &.change{
    color: $primary;
    background: #fff;
    border: 1px solid #0DB551;
  }

  &.indicate, &.send{
    background: $btn-indicate;
  }
}

@media screen and (max-width: $sm) {
  .btn {
    display: flex;
    flex-direction: column;
    margin: 10px;
    text-overflow: ellipsis;
    word-break: break-all;
  }
}
</style>
